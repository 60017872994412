.button-tag{
    border-radius: 24px !important;
}

.button-green{
    background-color: #449175 !important;
    border-color: transparent !important;
}

#cardy{
    cursor: pointer;
}

.orangeCard {
    background-color: #ed6f26 !important;
}

.tag-wrap{
    display: block;
    margin-top: 1rem;
}


@media screen and (min-width: 500px){
    .tag-wrap{
        display: inline;
        margin-left: 1rem;
    }
}