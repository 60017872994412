.green-nav {
  background-color: #449175;
  font-size: large;
  font-weight: bold;
}

.post-job {
  background-color: #ed6f26 !important;
  border-color: #ed6f26 !important;
  background: 103px;
  border-radius: 10px;
  text-align: center;
  max-width: 110px;
  max-height: 45px;
}
.nav-links {
  background-color: transparent !important;
  border-color: #ed6f2600 !important;
  border-radius: 10px;
  
}
.button{
  background-color: #ed6f26 !important;
  border-color: #ed6f26 !important;
}
.button-hide{
  background-color:transparent !important;
  border-color: transparent !important;
  border-width: 0px !important;
  color: white !important;
}
.button-hide .btn{
  background-color:transparent !important;
  border-color: transparent !important;
  border-width: 0px !important;
  color: white !important;
}
.dropdown-menu {
  --bs-dropdown-bg: #449175 !important;
  --bs-dropdown-border-width: 0px !important;
  --bs-dropdown-min-width: 5rem !important;

  
}
.dropdown-item {
  margin-right: auto !important;
}